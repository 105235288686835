.footer {
    display: none;
    align-items: center;
    height: 200px;
    justify-content: space-between;
    padding: 0 8vw;
    background-color: #49596A;
    color: white;
}

.footerInfo h1 {
    padding-top: 0;
    margin-bottom: 0;
}

.footerLinks a {
    color: #00D7D7;
    margin: 10px;
    font-weight: 700;
}