@keyframes slideDown {
    from {
      transform: translateY(-25%); /* Start from above the top of the page */
      opacity: 0; /* Optional: Start from transparent */
    }
    to {
      transform: translateY(0); /* End at their natural position */
      opacity: 1; /* Optional: End at full opacity */
    }
  }
  

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8vw;
    height: 100px;
    background: rgb(73, 89, 106);
    background: linear-gradient(90deg, rgba(73, 89, 106, 1) 0%, rgba(35, 31, 32, 1) 37%);
}

.navbarLogo {
    display: flex;
    align-items: center;
    animation: slideDown 0.5s ease-out forwards;
}

.navbarLogo h1 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 1.6rem;
    padding: 0;
}

.navbarImage {
    max-width: 100px;
    margin-right: 4vw;
}

.navLinks {
    display: flex;
    align-items: center;
}

.navLinks li {
    display: flex;
    align-items: center;
    margin: 10px;
}

.navLinks svg {
    font-size: 35px;
    color: #ffffff;
}

.LInavIcon {
    position: relative;
    width: 30px;
    /* Match the SVG width */
    height: 30px;
    /* Match the SVG height */
    display: inline-block;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 0.2s;
}

.navIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.GHnavIcon {
    position: relative;
    width: 40px;
    /* Match the SVG width */
    height: 40px;
    /* Match the SVG height */
    display: inline-block;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 0.4s;
}

.githubIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 33px !important;
}


.GHnavIcon:hover .iconBorder circle {
    stroke: #ffffff;
    transition: stroke 0.3s;
}

.LInavIcon:hover .iconBorder rect {
    stroke: #ffffff;
    transition: stroke 0.3s;
}

.navButtons {
    display: flex;
}

.buttonFill {
    display: inline-block;
    padding: 5px 25px;
    border-radius: 20px;
    text-decoration: none;
    background-color: #00D7D7;
    border: 1px solid #00D7D7;
    color: #231F20;
    transition: background-color 0.3s, border 0.3s;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 0.6s;
}

.buttonFill:hover {
    background-color: white;
    border: 1px solid white;
    color: #231F20;
}

.buttonLink {
    display: inline-block;
    padding: 5px 25px;
    color: #ffffff;
    text-decoration: none;
    border-radius: 20px;
    border: 1px solid #ffffff;
    transition: color 0.3s, border 0.3s, background-color 0.3s;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 0.8s;
}

.buttonLink:hover {
    background-color: white;
    border: 1px solid white;
    color: #231F20;
}

.navButtonsIcons {
    display: none;
}

.EMAILnavIcon {
    position: relative;
    width: 33px;
    height: 27px;
    display: inline-block;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 0.6s;
}

.EMAILnavIcon:hover .iconBorder rect {
    stroke: #ffffff;
    transition: stroke 0.3s;
}

.RESnavIcon {
    position: relative;
    width: 30x;
    height: 30px;
    display: inline-block;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 0.8s;
}

.RESnavIcon:hover .iconBorder rect {
    stroke: #ffffff;
    transition: stroke 0.3s;
}

.iconBorder {
    stroke-width: 2px; 
}


.iconBorder.safari {
    stroke-width: 3px;    
}

@media (max-width: 725px) {
    .navbar {
        flex-direction: column;
        height: 150px;
    }

    .navbarLogo {
        margin-top: 20px;
        flex-direction: column;
    }

    .navbarImage {
        margin: 0;
    }

    .navButtonsIcons {
        display: flex;
    }

    .navButtons {
        display: none;
    }
}