.projects {
    background-color: white;
}

.projectPicContainer {
    width: 300px;
    position: relative;
  }

  .projectPicContainer:after {
    background: #00D7D7;
    opacity: 0.3;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: ' ';
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .projectPicContainer:hover:after {
    opacity: 0;
  }
  
  .projectPic {
    filter: grayscale(1);
    transition: all 0.4s ease-in-out;
  }

  .projectPicContainer:hover .projectPic {
    filter: grayscale(0);
  }


.projectTitle {
    display: flex;
    align-items: center;
}

.projectButtons {
    display: flex;
    align-items: center;
}

.projButton {
    margin-left: 10px;
    display: inline-block;
    padding: 5px 25px;
    color: #231F20;
    text-decoration: none;
    border-radius: 20px;
    border: 2px solid #00D7D7;
    font-weight: 700;
    transition: border 0.3s, background-color 0.3s;
    cursor: pointer;
}

.projButton:hover {
    color: #231F20;
    border: 2px solid white;
    background-color: white;
}

.GHprojIcon {
    color: white;
    position: relative;
    width: 40px;  /* Match the SVG width */
    height: 40px; /* Match the SVG height */
    display: inline-block;
    margin-left: 10px;
}

.GHprojBorder {
    stroke:#00D7D7;
    fill:#231F20;
    transition: stroke 0.3s;
}

.GHprojIcon:hover .GHprojBorder {
    stroke: white;
}


.projectBullets {
    padding-left: 20px;
}





.projectContainer {
    display: flex;
    margin: 100px 0;
}

.projectTitleBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 2vh;
}

.projectTitleBlock h1 {
    margin-left: auto;
    padding-top: 0;
    margin-bottom: 10px;
    font-size: 1.4em;
}

.projectTitleBlockB {
    display: flex;
    flex-direction: column;
    margin-bottom: 2vh;
}

.projectTitleBlockB h1 {
    padding-top: 0;
    margin-bottom: 10px;
    font-size: 1.4em;
}

.projectTitleNumber {
    color: #00D7D7;
}

.projectSkillsBlock {
    display: flex;
    justify-content: end;
    margin-left: -50px;
    z-index: 110;
}

.projectSkillsBlock div {
    color: #231F20;
    border: 1px solid #00D7D7;
    background-color: #00D7D7;
    padding: 4px 10px; 
    display: inline-block; 
    margin: 5px; 
    border-radius: 5px; 
    font-size: 0.8em; 
    white-space: nowrap;
    text-align: center;
    width: fit-content;
    flex: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.projectSkillsBlockB {
    display: flex;
    justify-content: start;
    margin-right: -50px;
    z-index: 110;
}

.projectSkillsBlockB div {
    color: #231F20;
    border: 1px solid #00D7D7;
    background-color: #00D7D7;
    padding: 4px 10px; 
    display: inline-block; 
    margin: 5px; 
    border-radius: 5px; 
    font-size: 0.8em; 
    white-space: nowrap;
    text-align: center;
    width: fit-content;
    flex: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.projectDescriptionBlock {
    background-color: #231F20;
    color: white;
    padding: 20px;
    position: relative;
    z-index: 110;
    margin-left: -50px;
    text-align: end;
}

.projectDescriptionBlock p {
    font-weight: 400;
}

.projectDescriptionBlockB {
    background-color: #231F20;
    color: white;
    padding: 20px;
    position: relative;
    z-index: 110;
    margin-right: -50px;
    text-align: start;
}

.projectDescriptionBlockB p {
    font-weight: 400;
}

.projectPicContainer {
    max-width: 700px;
    z-index: 100;
    flex: 3;
    flex-shrink: 0;
}

.projectContentBlock {
    flex: 1;
}


.projectIcon {
    color: white;
    height: 20px;
    margin: 5px;
}

.projectIcon:hover {
    transition: color 0.3s;
    color:#00D7D7;
}

.projectIconBlock {
    height: 28px;
    margin-top: 10px;
}

@media screen and (min-width: 1600px) {
    .projectPicContainer {
        max-width: 850px;
    }

    .projectDescriptionBlock p {
        font-size: 1.2em; 
    }

    .projectDescriptionBlockB p {
        font-size: 1.2em; 
    }

    .projectTitleBlock h1 {
        font-size: 1.8em;
    }

    .projectTitleBlockB h1 {
        font-size: 1.8em;
    }

    .projectSkillsBlock {
        font-size: 1.1em;
    }
    
    .projectSkillsBlockB {
        font-size: 1.1em;
    }
    
    .projectIcon {
        height: 30px !important;
        width: auto !important;
    }
    
    .projectIconBlock {
        height: 36px;
        margin-top: 10px;
    }
}


@media screen and (max-width: 1175px) {
    .projectTitleBlock h1 {
        font-size: 1.2em;
    }

    .projectTitleBlockB h1 {
        font-size: 1.2em;
    }

    .projectSkillsBlock div {
        font-size: 0.7em; 

    }

    .projectDescriptionBlock p {
        font-size: 0.8em; 

    }

    .projectDescriptionBlockB p {
        font-size: 0.8em; 

    }
}

@media screen and (max-width: 1000px) {
    .projectContainer {
        margin: 75px 0;
    }

    .projectPicContainer {
        display: none;
    }

    .commonCentsProjectBlock {
        background-image: url("../assets/projectPics/commonCentsBlue.png");
        background-size: cover;
    }

    .jobModeProjectBlock {
        background-image: url("../assets/projectPics/jobModeBlue.png");
        background-size: cover;
    }


    .projectDescriptionBlock {
        background-color: transparent;
        color: white;
        padding-top: 0;
        position: relative;
        z-index: 110;
        margin-left: 5vw;
        text-align: end;
    }

    .projectDescriptionBlockB {
        background-color: transparent;
        color: white;
        padding-top: 0;
        position: relative;
        z-index: 110;
        margin-right: 5vw;
        text-align: start;
    }

    .projectTitleBlock h1 {
        color: white;
        padding: 20px 20px 0 0;
        margin-bottom: 0;
    }
    
    .projectTitleBlockB h1 {
        color: white;
        padding: 20px 0 0 20px;
        margin-bottom: 0;
    }

    .projectSkillsBlock {
        display: flex;
        justify-content: end;
        padding-right: 20px;
    }
    
    .projectSkillsBlock div {
        color: white;
        background-color: transparent;
        font-size: 0.7em; 
    }

    .projectSkillsBlockB {
        display: flex;
        justify-content: start;
        padding-left: 20px;
    }
    
    .projectSkillsBlockB div {
        color: white;
        background-color: transparent;
        font-size: 0.7em; 
    }
}

@media screen and (max-width: 530px) {
    .projectSkillsBlock div {
        font-size: 0.5em; 
        margin-left: 0;
        padding: 2px 5px; 
    }
    
    .projectSkillsBlockB div {
        font-size: 0.5em; 
        margin-left: 0;
        padding: 2px 5px; 
    }
}
