.skills {
   background-color: #F1F2F2;
   display: flex;
   padding-top: 50px;
}

.skillsTile {
    background-color: white;
    border-radius: 8px;
    flex: 1;
    text-align: center;
    margin: 10px;
    padding: 30px 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.skillsTile h2 {
    padding-top: 10px;
}

.skillListItem {
    padding: 5px;
}

@media screen and (min-width: 1600px) {
    .skillsTile {
        font-size: 1.4em;
    }
}

@media screen and (max-width: 600px) {
 .skills {
    flex-direction: column;
 }
}
