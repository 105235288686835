body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #231F20;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500; 
    padding-top: 50px;
    margin-bottom: 20px;
}

p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

a {
    color: #007BFF;
    text-decoration: none;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #0056b3;
}

ul,
ol {
    list-style-type: none;
    margin-left: 0px;
    padding-left: 0;
}


img {
    max-width: 100%;
    height: auto;
    display: block;
}
