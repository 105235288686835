h1 {
    font-size: 1.4rem;
}

li {
    margin-bottom: 20px;
}

.row {
    padding: 0px 8vw 50px;

}

.hero {
    display: flex;
    padding-top: 50px;
}

.heroText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 1.2s;
}

.heroText h1 {
    padding-top: 0;
}

.heroAccentText {
    color: #00D7D7;
    font-weight: 700;
}

.heroPic {
    margin-left: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
    flex: 1;
    opacity: 0;
    animation: slideDown 0.5s ease-out forwards;
    animation-delay: 1.4s;
}

.heroPic img {
    grid-area: 1 / 1;
    max-height: 400px;
}

.heroPicBen {
    transform: translatey(0px);
    transform: translatey(5px) translatex(5px);
    transition: transform 0.6s ease;

    img {
        width: 100%;
        height: auto;
    }
}

.heroPicBen:hover {
    transform: translatey(-5px) translatex(-5px);
}

.experience {
    display: flex;
}

.experience section {
    flex: 1;
}

.projectsExp {
    padding-right: 6vw;
}

.history {
    padding-left: 6vw;
}

.strongBlue {
    color: #00D7D7;
    text-decoration: underline;
}

@media screen and (min-width: 1600px) {
    .hero {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .heroText {
        font-size: 1.4em;
        margin-right: 100px;
    }

    .heroText h1 {
        font-size: 1.4em;
    }

    .heroPic img {
        max-height: 800px;
    }
}

@media (max-width: 725px) {
    .hero {
        flex-direction: column;
    }

    .heroPic {
        display: none;
    }

    .experience {
        flex-direction: column;
    }

    .history {
        padding-left: 0;
    }
}